import request from '@/utils/request'
// 打印后回调
export function setPrint(data) {
  return request({
    url: '/set_print',
    method: 'get',
    params: data,
  })
}


// 获取档案信息
export function loadReport(data) {
  return request({
    url: '/get_result_by_id',
    method: 'get',
    params: data,
  })
}

// 用户查看结果
export function getUserReport(data) {
  return request({
    url: '/get_detail_user_id',
    method: 'get',
    params: data,
  })
}

// 团体用户报告
export function groupStatistics(data) {
  return request({
    url: '/group_statistics',
    method: 'post',
    data,
  })
}


// 保存編輯内容
export function save_meaconclusion(data) {
  return request({
    url: '/save_meaconclusion',
    method: 'post',
    data,
  })
}

export function loadReportStatistical(data) {
  return request({
    url: '/report_statistics',
    method: "get",
    params: data
  })
}

export function loadReportStatistical2(data) {
  return request({
    url: '/report_statistics_2',
    method: 'get',
    params: data
  })
}
